import { render } from "./_photo.vue?vue&type=template&id=21815667"
import script from "./_photo.vue?vue&type=script&lang=js"
export * from "./_photo.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./_photo.vue?vue&type=style&index=0&id=21815667&lang=scss&module=true"
cssModules["$style"] = style0
import "./_photo.vue?vue&type=style&index=1&id=21815667&lang=scss"
script.render = render

export default script