<template>
  <form @submit.prevent="handleSubmit(!v$.$invalid)" class="_form">
    <label class="p-field mb-4" :class="{'p-invalid': v$.vin.$invalid && v$.vin.$dirty}">
      <span class="p-label"> <span v-t="'vehicles_page.vin'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.vin.$model"
        @blur="v$.vin.$touch()"
      />
    </label>
    <div class="grid">
      <div class="md:col-6">
        <Button class="p-button-big _full-width" type="button" @click="handleSubmit(!v$.$invalid, true)" :disabled="submiting"><span v-t="'vehicles_page.add_vehicle'"></span></Button>
      </div>
      <div class="md:col-6">
        <Button class="p-button-big p-button-outlined p-button-primary _full-width" type="submit" :disabled="submiting"><span v-t="'vehicles_page.add_vehicle_more'"></span></Button>
      </div>
    </div>
  </form>
</template>

<script>

import { ref } from 'vue';
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import API from '../../../api'
export default {
  components: {
    InputText,
    Button,
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    const { t } = useI18n()
    const router = useRouter()
    const submiting = ref(false);

    const model = ref({});
    const rules = {
      vin: { required },
    };
    const v$ = useVuelidate(rules, model)
    const handleSubmit = async (isFormValid, isGotoFormVehicle) => {
      v$.value.$touch()
      if (!isFormValid) {
        return;
      }
      submiting.value = true
      model.value.vehicle_name = `${model.value.brand} ${model.value.model}`
      model.value = {
        background_id: store.state.account.data.settings.background_id,
        background_type: store.state.account.data.settings.background_type,
        logo_id: store.state.account.data.settings.logo_id,
        logo_position: store.state.account.data.settings.logo_position,
        licence_plate_id: store.state.account.data.settings.licence_plate_id,
        preset_id: store.state.account.data.settings.preset_id,
        cutting: store.state.account.data.settings.cutting,
        plate_blur: store.state.account.data.settings.plate_blur,
        plate_image: store.state.account.data.settings.plate_image,
        polishing: store.state.account.data.settings.polishing,
        rim_polishing: store.state.account.data.settings.rim_polishing,
        reflective_floor: store.state.account.data.settings.reflective_floor,
        ...model.value}
      delete model.value.id
      delete model.value.user_id
      try {
        await API.cars('post', 'cars', model.value)
        toast.add({severity:'success', summary: t('success'), detail: t('toast.vehicle_added'), life: 3000});
        if (isGotoFormVehicle) {
          router.push({ name: 'Vehicle', params: { vin: model.value.vin } })
        }
        v$.value.$reset()
        submiting.value = false
        model.value = {}
      } catch (error) {
        submiting.value = false
        toast.add({severity:'error', summary: t('error'), detail: error.message, life: 3000});
      }
    }
    return {
      v$,
      model,
      submiting,
      handleSubmit,
    }
  }
}
</script>

<style lang="scss" module>
</style>