<template>
  <div :class="$style.modal">
    <Toolbar class="p-4">
      <template #left>
        <p class="_heading-2">{{ current + 1 }}  {{ currentItem.title }}</p>
      </template>
      <template #right>
        <Button @click="() => callback(-1)" class="p-button-outlined p-button-gray p-button-rounded mr-3 p-button-only-icon">
          <Icon name="arrow_left" />
        </Button>
        <Button @click="() => callback(1)" class="p-button-outlined p-button-gray p-button-rounded mr-5 p-button-only-icon">
          <Icon name="arrow_right" />
        </Button>
        <Button @click="$emit('closeModal')" class="p-button-outlined p-button-gray p-button-rounded p-button-only-icon">
          <Icon name="close" />
        </Button>
      </template>
    </Toolbar>
    <div :class="$style.content">
      <img :src="substrateImg" alt="" :class="$style.substrate">
      <!-- <svg :class="$style.substrate" width="1920" height="1440" viewBox="0 0 1920 1440" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1440" fill="#ECEDF3"/></svg> -->
      <img
        alt=""
        :src="currentItem.base_64_image || currentItem.processed_url || currentItem.loaded_photo || currentItem.guide_line"
        :class="[$style.car, !(currentItem.base_64_image || currentItem.processed_url || currentItem.loaded_photo) && $style.carReference]"
      >
      <Loader :isOverlay="true" v-if="item.in_process" />
    </div>
    <Toolbar class="p-4">
      <template #left>
        <label :class="[$style.action, item.in_process || photoUploading[currentItem.id] && 'disabled']" class="p-button">
          <input type="file" name="" id="" :disabled="item.in_process || photoUploading[currentItem.id]" class="p-upload-input" @change="uploadFile">
          <span v-t="currentItem.loaded_photo ? 'vehicles_page.replacePhoto' : 'vehicles_page.upload_photo'" />
        </label>
      </template>

      <template #right>
        <Button :class="$style.action" @click="processing" :disabled="item.in_process || !currentItem.loaded_photo" class="p-button-outlined p-button-gray ml-4">
          <Icon name="upload-cloud" />
          <span v-t="'vehicles_page.processing'" />
        </Button>
      </template>
    </Toolbar>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import { dragscrollNext } from "vue-dragscroll";
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import 'vue-advanced-cropper/dist/style.css';

import { Icon, Loader } from '../../../components/ui'
import substrateImg from '../../../assets/img/substrate.png'

export default {
  components: {
    Toolbar,
    Button,
    Icon,
    Loader,
  },
  directives: {
    dragscroll: dragscrollNext,
  },
  props: {
    total: Number,
    selected: Number,
    item: Object
  },
  emits: ['changeItem'],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const toast = useToast()
    const { t } = useI18n()
    const imagePreviev = ref()
    const base64Image = ref()
    const current = ref(props.selected)

    const data = computed(() => store.state.car.data)
    const photoUploading = computed(() => store.state.car.photoUploading)
    const currentItem = computed(() => props.item)

    function handlingArrowKey(e) {
      switch (e.key) {
        case 'ArrowLeft':
          e.preventDefault()
          callback(-1)
          break
        case 'ArrowRight':
          e.preventDefault()
          callback(1)
          break
      }
    }

    function callback(mod) {
      const summ = current.value + mod
      if (summ >= 0 && summ < props.total) {
        current.value = summ
        emit('changeItem', current.value)
      }
    }

    function uploadFile(event) {
      const file = event.target.files[0]
      var reader = new FileReader();
      reader.onloadend = function() {
        currentItem.value.base_64_image = reader.result
      }
      reader.readAsDataURL(file);
      const body = {
        car: route.params.vin,
        photo: file,
        preset_item: props.item.id
      }
      store.dispatch('car/reqUploadPhoto', { body, toast, t })
    }
    function processing() {
      const body = { car: route.params.vin, photo: props.item.photo_id }
      store.dispatch('car/reqProcessPhoto', { body, toast, t })
    }

    function handlingImage({ canvas }) {
      base64Image.value = canvas.toDataURL();
    }

    onMounted(() => {
      document.addEventListener('keydown', handlingArrowKey, true)
    })
    onUnmounted(() => {
      document.removeEventListener('keypress', handlingArrowKey, true)
    })

    return {
      current,
      callback,
      data,
      handlingImage,
      processing,
      imagePreviev,
      uploadFile,
      base64Image,
      currentItem,
      photoUploading,
      substrateImg,
    }
  }
}
</script>

<style lang="scss" module>
  .modal {
    background-color: #fff;
    border-radius: $radius;
    max-width: 860px;
    width: 100%;
  }

  .action {
    min-width: 185px;
  }

  .content {
    position: relative;
    overflow: hidden;
    background-color: #ECEDF3;
  }

  .car {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    object-fit: cover;
  }

  .carReference {
    opacity: .5;
  }

  .noImage {
    opacity: 0;
  }

  .substrate {
    width: 100%;
    height: auto;
    display: block;
    max-height: calc(100vh - 290px);
  }

  .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .cropper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .msg {
    background-color: rgba($text_color_black, 0.6);
    width: calc(100% - 30px);
    position: absolute;
    left: 15px;
    top: 24px;
    color: #fff;
    border-radius: $radius-small;
    pointer-events: none;
  }

  .slider {
    width: 100px;
  }
</style>

<style lang="scss">
  .vue-advanced-cropper__background, .vue-advanced-cropper__foreground {
    background-color: transparent;
  }

  .vue-advanced-cropper {
    height: 100%;
    img {
      opacity: var(--opacity);
    }
  }

  .vue-simple-handler {
    border: 2px solid #000
  }
  .vue-bounding-box {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
</style>