<template>
  <Toolbar>
    <template #left>
      <label class="p-field-checkbox mr-7" v-if="!isDsplaySettings">
        <Checkbox v-model="isSelectAll" :binary="true"  value="all" @change="toggleAll" />
        <span class="p-field-checkbox-label" for="binary">Select all</span>
      </label>
    </template>

    <template #right>
      <div class="flex align-items-center" v-if="isSecelted && !isDsplaySettings">
        <label class="p-field-checkbox mr-5">
          <Checkbox v-model="selectOriginal" :binary="true"  value="original" />
          <span class="p-field-checkbox-label" for="binary">With original images</span>
        </label>
        <label class="p-field-checkbox mr-5">
          <Checkbox v-model="selectBanner" :binary="true"  value="banner" />
          <span class="p-field-checkbox-label" for="binary">With banner</span>
        </label>
        <Button @click="download" class="p-button-outlined p-button-only-icon p-button-gray mr-2 mb-4 mt-5">
          <Icon name="download" />
        </Button>
        <!-- <Button class="p-button-outlined p-button-only-icon p-button-gray mr-2 mb-4 mt-5">
          <Icon name="trash" />
        </Button> -->
        <Button @click="processing" class="p-button-outlined p-button-gray mr-4 mb-4 mt-5">
          <Icon name="upload-cloud" />
          <span v-t="'vehicles_page.processing'" />
        </Button>
      </div>
      <Button class="p-button-outlined p-button-secondary mr-2 p-button-only-icon mb-4 mt-5" @click="() => isDsplaySettings = !isDsplaySettings">
        <Icon name="settings" />
      </Button>
      <Button @click="deleteVehiclePopup($event)" class="p-button mb-4 mt-5">
        <Icon name="trash" />
        <span v-t="'vehicles_page.deleteVehicles'" />
      </Button>
    </template>
  </Toolbar>

  <div class="grid _relative" v-if="!isDsplaySettings">
    <Loader :isOverlay="!!data" v-if="state.isLoading" />
    <div
      v-for="(item, index) in data.preset.items"
      :key="item.id"
      class="col-12 md:col-6 lg:col-3"
    >
      <div
        :class="[$style.card]"
        class="flex flex-column align-items-center"
      >
        <svg :class="$style.substrate" width="1920" height="1440" viewBox="0 0 1920 1440" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1440" fill="#ECEDF3"/></svg>
        <img :src="item.processed_url || item.loaded_photo || item.guide_line" :class="[$style.image, !(item.processed_url || item.loaded_photo) && $style.imageReference]" alt="">
        <div class="flex align-items-center w-full mb-5" :class="$style.actions">
          <p :class="$style.position" class="py-1 px-3 mr-2 _button-2">{{ index + 1 }}</p>
          <p :class="$style.position" class="py-1 px-3 _button-2">{{ item.title }}</p>
          <div  class="ml-auto">
            <Checkbox v-if="item.loaded_photo" @change="checkIsSelectedAll" :class="$style.checkbox" v-model="item.isSecelted" :value="item.id" :binary="true" />
          </div>
        </div>
        <Loader :isOverlay="true" v-if="item.in_process" />
        <button
          :class="$style.overlayAction"
          @click="() => openModal(index)"
        />
      </div>
    </div>
  </div>
  <Settings v-else />

  <Dialog v-model:visible="isShow" :modal="true" :draggable="false">
    <Modal :total="data.preset_items_count" :selected="selectedIndex" :item="$store.state.car.data.preset.items[selectedIndex]" @changeItem="openModal" @closeModal="() => isShow = false" />
  </Dialog>
</template>

<script>

import { computed, ref, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

import dateformat from 'dateformat'
import Toolbar from 'primevue/toolbar';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';


import { Icon, Loader } from '../../../components/ui'
import Modal from './_photo.vue'
import Settings from './_settings.vue'

export default {
  components: {
    Toolbar,
    Icon,
    Checkbox,
    Modal,
    Dialog,
    Button,
    Loader,
    Settings,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()
    const confirm = useConfirm();
    const { t } = useI18n()

    store.dispatch('car/reqBase', { car: route.params.vin })
    store.commit('header/setTitleComponent', 'CarTitle')


    const state = computed(() => store.state.car)
    const data = computed(() => store.state.car.data)
    const isDsplaySettings = ref(false)
    const isSelectAll = ref(false)
    const selectOriginal = ref(false)
    const selectBanner = ref(false)
    const isShow = ref(false)
    const selectedIndex  = ref(null)
    const isSecelted = ref(false)

    const selectables = computed(() => store.state.car.data.preset.items.filter(({ photo_url }) => photo_url))
    const withProcessedPhoto = computed(() => store.getters['car/withProcessedPhoto'])

    function getCar() {
      console.log(withProcessedPhoto.value);
      setTimeout(() => {
        if (withProcessedPhoto.value && store.state.car.isTestRequesting) {
          store.dispatch('car/reqBase', { car: route.params.vin, isTestRequest: true })
          getCar()
        }
      }, 5000)
    }


    watch(withProcessedPhoto, (newValue, oldValue) => {
      if (newValue) {
        store.commit('car/setIsTestRequesting', true)
        getCar()
      }
    })

    function openModal(index) {
      isShow.value = true
      selectedIndex.value = index
    }

    function toggleAll() {
      if (isSelectAll.value) {
        isSecelted.value = true
        selectables.value.forEach(element => {
          element.isSecelted = true
        });
        return
      }
      isSecelted.value = false
      selectables.value.forEach(element => {
        element.isSecelted = false
      });
    }

    function checkIsSelectedAll() {
      isSecelted.value = selectables.value.find(({ isSecelted }) => isSecelted)
      isSelectAll.value = selectables.value.length && selectables.value.find(({ isSecelted }) => !isSecelted) === undefined
    }

    function download() {
      const photosIDS = selectables.value
        .filter(({ isSecelted }) => isSecelted)
        .map(({photo_id}) => photo_id)

      if (photosIDS && photosIDS.length) {
        const body = { car: route.params.vin, photos: photosIDS, with_original: selectOriginal.value }
        store.dispatch('car/reqDownloadPhoto', {body, toast, t})
      }
    }

    function deleteVehicle() {
      store.dispatch('car/reqDelete', { car: route.params.vin, callback: () => router.push({ name: 'Vehicles' }) })
    }

    onUnmounted(() => {
      store.commit('header/setTitleComponent', '')
      store.commit('car/setIsTestRequesting', false)
      store.commit('car/resetData')
    })

    function processing() {
      selectables.value.filter(({ isSecelted }) => isSecelted).forEach(element => {
        const body = { car: route.params.vin, photo: element.photo_id }
        store.dispatch('car/reqProcessPhoto', { body, toast, t })
      });
    }

    const deleteVehiclePopup = (event) => {
        confirm.require({
          target: event.currentTarget,
          message: t('confirm_delete'),
          accept: () => {
            deleteVehicle()
          },
          reject: () => {

          }
        });
      }

    return {
      state,
      dateformat,
      isSelectAll,
      selectOriginal,
      selectBanner,
      selectedIndex,
      isSecelted,
      toggleAll,
      openModal,
      isShow,
      data,
      checkIsSelectedAll,
      download,
      isDsplaySettings,
      processing,
      deleteVehiclePopup,
    }
  }
}
</script>

<style lang="scss" module>
  .card {
    border-radius: $radius;
    background-color: $primary_light_grey;
    display: block;
    width: 100%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .substrate {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: $radius;
    opacity: 0;
  }

  .actions {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 3;
  }

  .position {
    background-color: $secondary_gray;
    border-radius: $radius-small;
    color: $text_color_gray;
  }

  .overlayAction {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .checkbox {
    position: relative;
    z-index: 2;
    background-color: rgba(#fff, 0.8);
    padding: 6px;
    border-radius: $radius-small;
  }

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: $radius;
  }

  .imageReference {
    opacity: .5;
  }

</style>