<template>
  <Toolbar>
    <template #left>
      <label class="p-field _base-search-size">
        <div class="p-input-icon-right">
          <InputText type="search" v-model="search" :placeholder="$t('vehicles_page.search')" />
          <Icon name="zoom" className="p-field-icon" />
        </div>
      </label>
    </template>

    <template #right>
      <router-link :to="{ name: 'CreatingVehicle' }" class="p-button mb-4  mt-5">
        <Icon name="plus" className="" />
        <span v-t="'vehicles_page.add_vehicle'" />
      </router-link>
    </template>
  </Toolbar>

  <Empty v-if="!state.isLoading && (!state.data || !state.data.length)"/>

  <div class="grid _relative">
    <Loader :isOverlay="state.data && state.data.length" v-if="state.isLoading" />
    <div
      v-for="item in state.data"
      :key="item.id"
      class="col-12 md:col-6 lg:col-4"
    >
      <router-link :class="$style.card" :to="{ name: 'Vehicle', params: { vin: item.vin } }">
        <div :class="$style.imgBox" class="flex align-items-center justify-content-center">
          <img :src="`${item.photo.url}`" v-if="item.photo" alt="" :class="$style.img" class="_img-base">
          <NoImg v-else />
        </div>
        <p :class="$style.name" v-if="item.vehicle_name">{{item.vehicle_name}}</p>
        <p :class="$style.name" v-else>{{item.brand}} {{item.model}}</p>
        <p :class="$style.vin">{{item.vin}}</p>
        <div :class="$style.footer" class="flex align-items-center justify-content-between">
          <div :class="[$style.slots, item.photos_count === item.preset_items_count && $style.full]" class="_label py-1 px-3">
            {{item.photos_count || 0}}/{{item.preset_items_count}}
          </div>
          <p :class="$style.date">{{dateformat(item.date, 'dd.mm.yyyy')}}</p>
        </div>
      </router-link>
    </div>
  </div>
  <Paginator
    :currentPage="state.meta.current_page || $route.query.page || 1"
    :total="state.meta.total"
    :lastPage="state.meta.last_page"
    :perPage="state.meta.perPage"
    :count="state.meta.to"
  />
</template>

<script>

import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import dateformat from 'dateformat'
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';


import { Icon, NoImg, Loader, Empty } from '../../components/ui'
import { Paginator } from '../../components/common'

export default {
  components: {
    Toolbar,
    InputText,
    Icon,
    NoImg,
    Paginator,
    Loader,
    Empty,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const search = ref()
    store.dispatch('cars/reqBase', { per_page: 9, page: route.query.page || 1, search: search.value  })
    const state = computed(() => store.state.cars)

    watch(route, () => {
      store.dispatch('cars/reqBase', { per_page: 9, page: route.query.page || 1, search: search.value })
    })

    watch(search, (newValue) => {
      router.push({ name: route.name, query: { search: newValue } })
    })

    return {
      state,
      dateformat,
      search,
    }
  }
}
</script>

<style lang="scss" module>
  .card {
    border-radius: $radius;
    background-color: $primary_light_grey;
    padding: 20px 24px;
    display: block;
  }

  .imgBox {
    height: 150px;
    margin-bottom: 24px;
  }

  .img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .withoutName {
    color: $text_color_gray;
  }
  .name {
    color: $text_color_black;
  }
  .withoutName,
  .name {
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
  }

  .vin {
    color: $text_color_gray;
    margin-top: 8px;
  }

  .footer {
    padding-top: 18px;
    margin-top: 18px;
    border-top: 1px solid $text_color_line_gray;
  }

  .slots {
    color: $text_color_dark_gray;
    background-color: $secondary_gray;
    border-radius: $radius-small;
  }

  .full {
    color: $secondary_green;
    background-color: $bg_green;
  }

  .date {
    color: $text_color_gray;
  }
</style>